const ALLOWED_HOSTS = [/(.+\.)?operationsfx\.com/, /pmfx(.+)\.wpengine\.com/];

/**
 * Redirect sign in to redirect URL if it is allowed
 *
 * @param user
 * @param redirectUrl
 */
const authRedirect = (auth, redirectUrl) => {
  if (!redirectUrl) {
    return false;
  }

  const url = new URL(redirectUrl);

  const allowed = ALLOWED_HOSTS.reduce((a, domain) => {
    /* eslint-disable no-param-reassign */
    if (typeof domain === 'string' && domain === url.host) {
      a = true;
    }
    if (domain instanceof RegExp && domain.test(url.host)) {
      a = true;
    }
    /* eslint-enable no-param-reassign */

    return a;
  }, false);

  if (allowed) {
    url.searchParams.set('authToken', auth.accessToken);
    window.location.href = url.toString();
  }
};

export default authRedirect;
