const publicPaths = [
  '/confirm-password',
  '/confirm-password/',
  '/signin',
  '/signin/',
  '/feedback/auth',
  '/feedback/auth/',
  /scheduler\/book-meeting\/*/g,
  /scheduler\/meet-with-me\/*/g,
  '/scheduler/redirect-page',
  '/scheduler/redirect-page/',
];

export const isPublicPath = (pathName) => {
  for (const path of publicPaths) {
    if (typeof path !== 'string') {
      if (pathName.match(path)) {
        return true;
      }
    } else if (pathName === path) {
      return true;
    }
  }
  return false;
};
