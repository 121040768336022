let api = window.location.host.includes('next')
  ? 'https://api.next.webfx.com'
  : 'https://api.webfx.com';

if (process.env.POI_APP_API) {
  api = process.env.POI_APP_API;
}

if (window.FX_APP_API) {
  api = window.FX_APP_API;
}

const fx_api = api; // force immutability
export default fx_api;
