import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';

import { initializeDatadog } from '@webfx/web-hooks';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { enableMapSet } from 'immer';

import '@webfx/core-web/src/assets/css/bootstrap.css';
import 'react-vertical-timeline-component/style.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './config';

import App from './ui/App';
import { authenticate, handleGoogleCredentialResponse } from './authenticate';

window.handleGoogleCredentialResponse = handleGoogleCredentialResponse;

enableMapSet();
initializeDatadog();

(async () => {
  await authenticate();

  const LDApp = withLDProvider({
    clientSideID: process.env.POI_APP_LAUNCH_DARKLY,
    context: {
      kind: 'user',
      key: 'anonymous_user',
      anonymous: true,
    },
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  })(App);

  ReactDOM.render(<LDApp />, document.getElementById('app'));
})();
