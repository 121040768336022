import { feathers, store } from '@webfx/core-web';
import { actions } from '@webfx/web-state';
import Cookies from 'js-cookie';
import qs from 'query-string';
import authRedirect from './utils/authRedirect';
import { isPublicPath } from './utils/isPublicPath';

// Force login on all not authenticated errors
feathers().hooks({
  async error(context) {
    if (context.error.code === 401) {
      handleNotLoggedError(context.error);
    }
  },
});

/**
 * Handle the response from the google credential
 * @param {*} response
 */
export async function handleGoogleCredentialResponse(response) {
  try {
    actions.auth.login({ strategy: 'google', credential: response?.credential });
  } catch (err) {
    console.error(err);
    await handleNotLoggedError(err);

    window.history.replaceState(
      {},
      `Sign In | MarketingCloudFX', '/signin?authError=${err?.message}`
    );
  }
}

/**
 * Handle if the user is not logged in, save the last viewed page and redirect to login
 */
async function handleNotLoggedError(error) {
  if (error?.code === 401) {
    await actions.auth.logout();
  }

  if (!isPublicPath(window.location.pathname)) {
    store
      .getActions()
      .router.setLastViewed({ pathname: window.location.pathname, search: window.location.search });
    window.history.replaceState({}, 'Sign In | MarketingCloudFX', '/signin');
  }
}

/**
 * Authenticate the user
 */
export async function authenticate() {
  const queryString = qs.parse(window.location.search);

  if (queryString.lastViewed) {
    store.getActions().router.setLastViewed({ pathname: queryString.lastViewed });
  }

  try {
    const apikey = Cookies.get('x-auth-apikey');
    if (apikey) {
      await actions.auth.login({ strategy: 'apikey', apikey, forceLogin: true });
    }
  } catch (err) {
    //  fail silently for authapi key
  }

  try {
    const auth = await feathers().reAuthenticate();

    if (auth.authentication.payload.user.userId === 3) {
      throw new Error('guest user');
    }

    if (queryString.redirectAuth) {
      authRedirect(auth, queryString.redirectAuth);
    }
  } catch (err) {
    // not authenticated
    if (err.code !== 401) {
      await actions.auth.logout({ skipLogout: err.message === 'Failed to fetch' });
    }

    // if has a guest token, then should do auth as guest instead of reAuth.
    if (queryString.accessToken) {
      try {
        await actions.auth.login({
          strategy: 'apikey',
          apikey: queryString.accessToken,
          forceLogin: true,
        });
        return; // return early if using guest token.
      } catch (err) {
        await handleNotLoggedError(err);
      }

      return;
    }

    await handleNotLoggedError(err);
  }
}
